import React, { useEffect, useState, useRef } from "react"

import { Button } from "components/Button"
import { DataTable } from "components/DataTable"
import { AutoComplete } from "components/AutoComplete"
import { Row, Col, Alert, Input, Label, Form } from "reactstrap"
import _ from "lodash"
import toast from "react-hot-toast"
import Config from "Config"
import moment from "moment"

import { PlaceOrderStatus } from "../../../../constants"
import dumyimage from "../../../../assets/images/image-upload-placeholder.png"
import { FinanceImageUpload } from "../../../Common/ImageUpload"
import {
   dateTimeFormat,
   convertDateTime,
} from "../../../../utils/dateFormatUtils"
import {
   finalizeMediaUtil,
   initializeMediaUtil,
   uploadOnS3,
} from "../../../../utils/mediaUtils"
import customStyles from "../HeaderStatusStyling"
import { ImageTypes } from "../../../../constants"
import {
   InventoryItemsById,
   VendorListing,
   CreateOrderFinance,
   DirectPOTransfer,
} from "../../../../api/api.service"

const COLUMNS = {
   SELECT_ALL: "Select All",
   ORDER_ID: "Order ID",
   SKU: "SKU No.",
   ITEM_NAME: "Item Name",
   REQUESTED_QUANTITY: "Requested Quantity",
   UNIT_PRICE: "Unit Price",
   TOTAL_PRICE: "Total Price",
   STATUS: "Status",
   ORDERED_QUANTITY: "Ordered Quantity",
   QUANTITY_REMANING: "Quantity Remaining",
   DATE_REQUESTED: "Requested Date",
}

const MANIFEST_SUPPORTED_FILES = [...ImageTypes, "pdf"]
const errorsData = ["", "Field Required", "quantity exceeds"]
const KB_CONVERTER = 1024

const statusForPayload = status => {
   return status === "order placed"
      ? "OrderPlaced"
      : status === "order substitute"
      ? "OrderedSubstitute"
      : "ParitalOrdered"
}

const addElementToData = (dataList, warehouse = null) => {
   console.log("🚀 ~ addElementToData ~ dataList:", dataList)
   let modifiedArr = _.cloneDeep(dataList)
   modifiedArr.map(el => {
      el.unitPrice = ""
      el.totalPrice = 0
      el.orderedQuantity = ""
      el.isStatusSelect = true
      el.isItemSelect = false
      el.isInputDisabled = true
      el.UPError = 0
      el.quantityError = 0
      el.itemData = {
         data: [],
         fetching: true,
         page: 1,
         Q: "",
         isDataAvailable: false,
         selectedOption: "",
      }
      if (warehouse) {
         el.status = {
            label: PlaceOrderStatus.getDisplayTextKey(PlaceOrderStatus.OPEN),
            value: PlaceOrderStatus.OPEN,
         }
         el.UPError = 1
         el.isInputDisabled = false
         el.orderedQuantity = (
            Number(el.maxQuantity) - Number(el.warehouseQuantity)
         )?.toFixed(2)
      } else if (el.substituteId === null) {
         el.status = {
            label: PlaceOrderStatus.getDisplayTextKey(PlaceOrderStatus.OPEN),
            value: PlaceOrderStatus.OPEN,
         }
         el.isAlreadySubstitute = false

         el.orderedQuantity = (
            Number(el.quantity) - Number(el.quantityreceived)
         )?.toFixed(2)
         el.substituteId = null
      } else {
         el.status = {
            label: PlaceOrderStatus.getDisplayTextKey(
               PlaceOrderStatus.IN_PROGRESS
            ),
            value: PlaceOrderStatus.IN_PROGRESS,
         }

         el.isAlreadySubstitute = true
         el.UPError = 1
         el.isInputDisabled = false
         el.orderedQuantity = (
            Number(el.quantity) - Number(el.quantityreceived)
         )?.toFixed(2)
      }
   })
   return modifiedArr
}
const PlaceOrderModal = ({
   data,
   title,
   footerBtnType,
   modalCloseHandler,
   dependancyAPIHandler,
   warehouse = null,
}) => {
   let controller = new AbortController()
   let signal = controller.signal
   const [poNo, setPONumber] = useState("")
   const [showError, setShowError] = useState(false)
   const [modifiedData, setModifiedData] = useState(
      addElementToData(data, warehouse)
   )
   const [image, setImage] = useState("")
   const [imagePreview, setImagePreview] = useState(dumyimage)
   const [fileName, setFileName] = useState(undefined)
   const [imageError, setImageError] = useState(undefined)
   const [isAddingRequest, setIsAddingRequest] = useState()
   const [errorType, setErrorType] = useState(1)
   const [itemSelectData, setItemSelectData] = useState({
      searchValueUpdated: undefined,
      selectedRowIDTypeChange: undefined,
      selectedRowID: undefined,
      selectedRowIDTypeChangeModified: undefined,
      selectedRowIDModified: undefined,
   })
   const [addVendorListing, setAddVendorListing] = useState({
      data: [],
      fetching: true,
      page: 1,
      Q: "",
      isDataAvailable: false,
   })
   const [selectedOptionAV, setSelectedOptionAV] = useState({
      name: "",
      label: "",
      value: "",
   })
   const errorMsgRef = useRef()
   // handle file change
   const handleFileChange = e => {
      let file = e.target.files[0]
      if (file) {
         const uploadedFileType = file?.type?.split("/")[1]?.toLowerCase()
         let fileSize = file && file?.size
         fileSize = fileSize / KB_CONVERTER
         if (!MANIFEST_SUPPORTED_FILES.includes(uploadedFileType)) {
            // setImageUploaded(true)
            setImageError("File type is not supported!")
            // setImageUploaded(false)
            setFileName(undefined)
            setImage("")
            // setImagePreview("")
         } else {
            setFileName(file?.name)
            setImageError("")
            let reader = new FileReader()
            reader.onload = e => {
               let imagePreview = e.target?.result
               setImage(file)
               // setImagePreview(imagePreview)
            }
            reader.readAsDataURL(file)
         }
      }
   }

   // hanlde remove image
   const removeImage = () => {
      setFileName(undefined)
      setImageError(undefined)
      setImage("")
   }
   // handle Input change
   const handleInputChange = (e, id) => {
      let updatedData = _.cloneDeep(modifiedData)
      updatedData.map(el => {
         if (el.id === id) {
            // check for error
            if (e.target.name === "unitPrice") {
               el[e.target.name] = e.target.value
               el.totalPrice = Number(el.unitPrice) * Number(el.orderedQuantity)
               el.unitPrice > 0 ? (el.UPError = 0) : (el.UPError = 1)
            }
            if (e.target.name === "orderedQuantity") {
               const modifiedValue = e.target.value.replace(/[^0-9.]/g, "")
               el[e.target.name] = modifiedValue
               el.totalPrice = Number(el.unitPrice) * Number(el.orderedQuantity)
               el.orderedQuantity > 0
                  ? (el.quantityError = 0)
                  : (el.quantityError = 1)

               if (warehouse) {
                  if (
                     Number(el.orderedQuantity) >
                     Math.abs(el.maxQuantity - el.warehouseQuantity)
                  ) {
                     el.quantityError = 2
                  }
               } else {
                  if (
                     el.orderedQuantity >= el.quantity - el.quantityreceived &&
                     el.status.value === "partially ordered"
                  ) {
                     el.quantityError = 2
                  }
                  if (
                     el.orderedQuantity > el.quantity - el.quantityreceived &&
                     el.status.value === "order substitute"
                  ) {
                     el.quantityError = 2
                  }
                  if (
                     el.orderedQuantity > el.quantity - el.quantityreceived &&
                     el.isAlreadySubstitute
                  ) {
                     el.quantityError = 2
                  }
               }
            }
         }
      })
      setModifiedData(updatedData)
   }
   // update input field number float
   const formatInput = (e, id) => {
      let updatedData = _.cloneDeep(modifiedData)
      updatedData.map(el => {
         if (el.id === id) {
            const num = Number(el.unitPrice)
            el[e.target.name] = parseFloat(num).toFixed(2)
         }
      })
      setModifiedData(updatedData)
   }

   const statusOptions =
      PlaceOrderStatus &&
      Object.keys(PlaceOrderStatus)
         .map(el => {
            if (el !== "PENDING") {
               return {
                  label: PlaceOrderStatus.getDisplayTextKey(
                     PlaceOrderStatus[el]
                  ),
                  value: PlaceOrderStatus[el],
               }
            }
         })
         .filter(el => el !== undefined)

   // handle select status change
   const handleSelectStatusChange = (val, id, itemsId) => {
      if (val.value == "order substitute") {
         setItemSelectData({
            ...itemSelectData,
            searchValueUpdated: undefined,
            selectedRowIDTypeChange: id,
            selectedRowIDTypeChangeModified: itemsId,
         })
      }
      let updatedData = _.cloneDeep(modifiedData)
      updatedData.map(el => {
         if (el.id === id) {
            el.status.value = val.value
            if (el.unitPrice <= 0) el.UPError = 1
            if (val.value == "order placed") {
               el.orderedQuantity = (
                  Number(el.quantity) - Number(el.quantityreceived)
               )?.toFixed(2)
               el.isItemSelect = false
               el.isInputDisabled = true
               el.substituteId = null
               el.quantityError = 0
               el.status = {
                  label: PlaceOrderStatus.getDisplayTextKey(
                     PlaceOrderStatus.OPEN
                  ),
                  value: PlaceOrderStatus.OPEN,
               }
            } else if (val.value == "partially ordered") {
               el.status = {
                  label: PlaceOrderStatus.getDisplayTextKey(
                     PlaceOrderStatus.COMPLETED
                  ),
                  value: PlaceOrderStatus.COMPLETED,
               }

               el.isItemSelect = false
               el.orderedQuantity = ""
               el.isInputDisabled = false
               el.substituteId = null
               el.quantityError = 1
            } else if (val.value == "order substitute") {
               el.status = {
                  label: PlaceOrderStatus.getDisplayTextKey(
                     PlaceOrderStatus.IN_PROGRESS
                  ),
                  value: PlaceOrderStatus.IN_PROGRESS,
               }
               el.orderedQuantity = (
                  Number(el.quantity) - Number(el.quantityreceived)
               )?.toFixed(2)
               el.isItemSelect = true

               el.isInputDisabled = true
               el.quantityError = 0
               el.itemData.selectedOption = ""
               el.substituteId = null
            }
            el.totalPrice = Number(el.unitPrice) * Number(el.orderedQuantity)
         }
      })
      setModifiedData(updatedData)
   }
   // handle select item change
   const handleSelectItemChange = (val, id) => {
      let updatedData = _.cloneDeep(modifiedData)
      updatedData.map(el => {
         if (el.id === id) {
            el.substituteId = val ? val.value : null
            el.isInputDisabled = val ? false : true
            el.itemData.selectedOption = val ? val.label : ""
            // el.quantityError = val ? 1 : 0
         }
      })
      setModifiedData(updatedData)
   }

   // API call for select item on value change
   useEffect(() => {
      if (
         itemSelectData.selectedRowID &&
         itemSelectData.searchValueUpdated !== undefined
      )
         getItemsListingData()

      return () => {
         controller.abort()
      }
   }, [itemSelectData.searchValueUpdated])

   // API call for select item on type change
   useEffect(() => {
      if (itemSelectData.selectedRowIDTypeChange)
         getItemsListingDataTypeChange()

      return () => {
         controller.abort()
      }
   }, [itemSelectData.selectedRowIDTypeChange])
   // API call function
   const getItemsListingData = () => {
      handleStateSet(
         setModifiedData,
         { fetching: true, data: [] },
         itemSelectData.selectedRowID
      )
      InventoryItemsById(
         itemSelectData.selectedRowIDModified,
         {
            limit: Config.LIMIT,
            ...(itemSelectData.searchValueUpdated && {
               Q: itemSelectData.searchValueUpdated,
            }),
            page: 1,
         },
         signal
      )
         .then(res => {
            let updatedData = _.cloneDeep(modifiedData)
            updatedData.map(el => {
               if (el.id === itemSelectData.selectedRowID) {
                  ;(el.itemData.fetching = false),
                     (el.itemData.isDataAvailable =
                        res?.data.length === Config.LIMIT),
                     (el.itemData.page = 1)
                  el.itemData.data = (() => {
                     let test = res?.data
                        .map(item => ({
                           ...item,
                           value: item.id,
                           label: item.itemName,
                        }))
                        .filter(innerEl => el.skuNo !== innerEl.skuNo)
                     return [...test]
                  })()
               }
            })
            setModifiedData(updatedData)
         })
         .finally(() => {
            handleStateSet(
               setModifiedData,
               { fetching: false },
               itemSelectData.selectedRowID
            )
         })
   }

   const getItemsListingDataTypeChange = () => {
      handleStateSet(
         setModifiedData,
         { fetching: true, data: [] },
         itemSelectData.selectedRowIDTypeChange
      )
      InventoryItemsById(
         itemSelectData.selectedRowIDTypeChangeModified,
         {
            limit: Config.LIMIT,
            ...(itemSelectData.searchValueUpdated && {
               Q: itemSelectData.searchValueUpdated,
            }),
            page: 1,
         },
         signal
      )
         .then(res => {
            let updatedData = _.cloneDeep(modifiedData)
            updatedData.map(el => {
               if (el.id === itemSelectData.selectedRowIDTypeChange) {
                  ;(el.itemData.fetching = false),
                     (el.itemData.isDataAvailable =
                        res?.data.length === Config.LIMIT),
                     (el.itemData.page = 1)
                  el.itemData.data = (() => {
                     let test = res?.data
                        .map(item => ({
                           ...item,
                           value: item.id,
                           label: item.itemName,
                        }))
                        .filter(innerEl => el.skuNo !== innerEl.skuNo)
                     return [...test]
                  })()
               }
            })
            setModifiedData(updatedData)
         })
         .finally(() => {
            handleStateSet(
               setModifiedData,
               { fetching: false },
               itemSelectData.selectedRowIDTypeChange
            )
         })
   }

   // handle select search
   const handleSearch = (value, id, itemsId) => {
      if (!value) return
      let updatedData = _.cloneDeep(modifiedData)
      updatedData.map(el => {
         if (el.id === id) {
            el.itemData.data = []
            el.itemData.page = 1
            el.itemData.Q = value
         }
         setModifiedData(updatedData)
         setItemSelectData({
            ...itemSelectData,
            selectedRowID: id,
            selectedRowIDModified: itemsId,
            searchValueUpdated: value,
         })
      })
   }

   // handle stateset
   const handleStateSet = (callback, keysObj = {}, id) => {
      callback(prev => {
         let updatedData = _.cloneDeep(prev)
         updatedData.map(el => {
            if (el.id === id) {
               el.itemData = { ...el.itemData, ...(keysObj && keysObj) }
            }
         })
         return updatedData
      })
   }
   // handle Item blur
   const handleItemSelectBlur = (callback, id) => {
      setItemSelectData({
         ...itemSelectData,
         searchValueUpdated: "",
      })
   }

   // ADD VENDOR LOGIC //

   // API request for department listing
   useEffect(() => {
      addVendorLogic(addVendorListing.page)

      return () => {
         controller.abort()
      }
   }, [addVendorListing.Q])

   // sku select API call function
   const addVendorLogic = page => {
      handleStateSetAddVendor(setAddVendorListing, {
         fetching: true,
         data: [],
      })
      VendorListing(
         {
            limit: Config.LIMIT,
            ...(addVendorListing.Q && { Q: addVendorListing.Q }),
            page: page,
         },
         signal
      )
         .then(res => {
            setAddVendorListing(prev => ({
               ...prev,
               fetching: false,
               page: page + 1,
               isDataAvailable: res?.data?.length === Config.LIMIT,
               data: (() => {
                  let test = res?.data?.map(item => ({
                     ...item,
                     value: item.id,
                     label: item.vendorName,
                  }))
                  return [...test, ...prev.data]
               })(),
            }))
         })
         .finally(() => {
            handleStateSetAddVendor(setAddVendorListing, {
               fetching: false,
            })
         })
   }

   // handle stateset
   const handleStateSetAddVendor = (callback = () => {}, keysObj = {}) => {
      callback(prev => {
         return {
            ...prev,
            ...(keysObj && keysObj),
         }
      })
   }

   // handle autocomplete onselect
   const handleSelectOptionAV = e => {
      setSelectedOptionAV({
         name: e ? e.vendorName : "",
         label: e ? e.label : "",
         value: e ? e.id : "",
      })
   }

   // handle select search
   const handleSearchAV = (value, callback) => {
      if (!value) return

      callback(prev => {
         return {
            ...prev,
            data: [],
            page: 1,
            Q: value,
         }
      })
   }

   // media upload on server
   const handleMediaUpload = async file => {
      return initializeMediaUtil(file).then(async res => {
         const credentials = res
         await uploadOnS3(file, credentials)
         return await finalizeMediaUtil(credentials?.mediaId)
      })
   }
   // scroll to top
   const scrollToTop = () => {
      errorMsgRef.current?.scrollIntoView({ block: "nearest" })
   }
   //HANDLE FORM SUMBIT
   const handleConfirmRequest = async () => {
      if (!image) {
         return toast.error("Kindly upload invoice!")
      }
      setShowError(false)
      setErrorType(1)
      let isError = false
      modifiedData.map(el => {
         if (selectedOptionAV.name === "") isError = true
         if (selectedOptionAV.value === "") isError = true
         if (el.status.value === "Pending") isError = true
         if (el.orderedQuantity <= 0) isError = true
         if (el.unitPrice <= 0) isError = true
         if (el.orderedQuantity > el.quantity - el.quantityreceived) {
            isError = true
            setErrorType(2)
         }
         if (el.status.value === PlaceOrderStatus.COMPLETED) {
            if (
               el.orderedQuantity ===
               Number(el.quantity) - Number(el.quantityreceived)
            )
               isError = true
         }
         if (el.status.value === "order substitute") {
            if (el.substituteId === null) isError = true
         }
      })
      if (isError) {
         scrollToTop()
         setShowError(true)
         return
      }

      let payloadObj = { orders: [] }
      if (warehouse) {
         payloadObj.warehouseId = Number(warehouse.id)
      }
      payloadObj.vendorId = selectedOptionAV?.value
      payloadObj.vendorName = selectedOptionAV?.name
      payloadObj.poNo = poNo
      modifiedData.map(el => {
         let tempObj = {}
         tempObj.status = el?.status.value && statusForPayload(el?.status.value)
         tempObj.quantity = Math.abs(Number(el?.orderedQuantity))
         tempObj.itemsId = el?.itemsId || el?.id
         tempObj.unitPrice = Math.abs(Number(el?.unitPrice))
         tempObj.totalPrice = Number(el?.totalPrice)
         if (el?.substituteId !== null) tempObj.substituteId = el.substituteId
         tempObj.warehouseRequestId = el?.warehouseRequestId
         tempObj.warehouseRequestItemId = el.id
         payloadObj.orders.push(tempObj)
      })
      try {
         setIsAddingRequest(true)
         if (image) {
            let imageRes = await handleMediaUpload(image)
            payloadObj.invoiceId = imageRes.id
         }
         warehouse
            ? await DirectPOTransfer(payloadObj)
            : await CreateOrderFinance(payloadObj)
         dependancyAPIHandler()
         toast.success("Order has been placed successfully.")
         modalCloseHandler()
      } catch (err) {
         // toast.error(err.message)
      } finally {
         setIsAddingRequest(false)
      }
   }
   return (
      <>
         <Form>
            <h3 className="text-left fm-req-popup-heading mb-0 px-5">
               {title}
            </h3>
            <div ref={errorMsgRef} className="mt-3 px-5">
               {showError &&
                  (errorType !== 2 ? (
                     <Alert color="danger">
                        Please insert the complete details.
                     </Alert>
                  ) : (
                     <Alert color="danger">
                        The ordered quantity is exceeding the requested
                        quantity.
                     </Alert>
                  ))}
            </div>
            <div className="pt-3 px-5">
               <Row>
                  <Col sm="6" xs="12" md="5" lg="5" className="my-auto">
                     <p className="place-order-top-date my-auto">
                        Date of Order Placed:{" "}
                        <span>{moment().format("MMM D, YYYY")}</span>
                     </p>
                  </Col>
                  <Col sm="6" xs="12" md="5" lg="5">
                     <Label
                        for="exampleEmail"
                        align="right"
                        className="px-0 addnewitem-typo table-column-bold"
                     >
                        PO No.
                     </Label>
                     <Input
                        className="place-order-head-input"
                        type="text"
                        name="Item Name"
                        id="exampleEmail"
                        placeholder="Type PO No"
                        value={poNo}
                        onChange={e => setPONumber(e.target.value)}
                     />
                  </Col>
                  <Col sm="6" xs="12" md="5" lg="5">
                     <Label
                        for="exampleSelect"
                        align="right"
                        className="pl-0 pr-0 px-0 sm-2 addnewitem-typo table-column-bold"
                     >
                        Add Vendor
                     </Label>
                     <div className="place-order-table-head-search ">
                        <AutoComplete
                           placeholder="Select Vendor"
                           customStyles={customStyles.WarehouseIM}
                           classNamePrefix="place-order-table-head-search"
                           options={addVendorListing?.data}
                           handleBlur={() =>
                              setAddVendorListing(prev => {
                                 return {
                                    ...prev,
                                    page: 1,
                                    Q: "",
                                 }
                              })
                           }
                           onChange={handleSelectOptionAV}
                           onInputChange={val =>
                              handleSearchAV(val, setAddVendorListing)
                           }
                           closeMenuOnSelect={true}
                           isClearable={true}
                           value={addVendorListing?.data?.filter(
                              x => selectedOptionAV.label === x.label
                           )}
                           isLoading={addVendorListing?.fetching}
                        />
                     </div>
                  </Col>

                  <Col
                     sm="6"
                     xs="12"
                     md="5"
                     lg="5"
                     className="place-order-img-main-con"
                  >
                     <FinanceImageUpload
                        image={imagePreview}
                        onChange={handleFileChange}
                        fileName={fileName}
                        imageError={imageError}
                        removeImageHandler={removeImage}
                     />
                  </Col>
                  <Col
                     sm="12"
                     xs="12"
                     md="12"
                     lg="12"
                     className="px-0 mb-4 mt-3"
                  >
                     <DataTable
                        data={modifiedData}
                        className=" mt-3 fm-place-order-table place-order-data-table"
                        tableClasses="finance-order-management-place-order-modal-table"
                        config={[
                           {
                              title: COLUMNS.SKU,
                              className: "table-column-bold",
                              render: data => data?.skuNo,
                           },
                           {
                              title: COLUMNS.ITEM_NAME,
                              render: data => data?.itemName,
                           },
                           {
                              title: COLUMNS.REQUESTED_QUANTITY,
                              className: "table-column-bold",
                              render: data =>
                                 warehouse ? "-" : data?.quantity,
                           },
                           {
                              title: COLUMNS.UNIT_PRICE,
                              render: data => {
                                 return (
                                    <div
                                       style={{
                                          minWidth: "170px",
                                       }}
                                    >
                                       <span className="d-inline-block input-append-dollar-con ps-2 placeorder-unit-price">
                                          $
                                          <Input
                                             style={{
                                                width: "90%",
                                                verticalAlign: "initial",
                                             }}
                                             onChange={e =>
                                                handleInputChange(e, data.id)
                                             }
                                             type="number"
                                             onBlur={e =>
                                                formatInput(e, data.id)
                                             }
                                             placeholder="0"
                                             name="unitPrice"
                                             className="view-detail-inv-req-p-ip-con create-order-modal-ip d-inline-block border-0 ps-0"
                                             value={data.unitPrice}
                                             step="0.000001"
                                             max="99999999.99"
                                             min="0"
                                          />
                                       </span>
                                       {
                                          <span className="text-danger d-block">
                                             {errorsData[data?.UPError]}
                                          </span>
                                       }
                                    </div>
                                 )
                              },
                           },
                           {
                              title: COLUMNS.TOTAL_PRICE,
                              className: "table-column-bold",
                              render: data =>
                                 `$${Number(data?.totalPrice).toFixed(2)}`,
                           },
                           {
                              title: COLUMNS.DATE_REQUESTED,
                              render: data => {
                                 return warehouse
                                    ? "-"
                                    : convertDateTime({
                                         date: data.createdAt,
                                         customFormat:
                                            dateTimeFormat.casitaCustomDateFormat,
                                         dateOnly: true,
                                      })
                              },
                           },
                           {
                              title: COLUMNS.STATUS,
                              render: data => {
                                 return (
                                    <div>
                                       {warehouse ? (
                                          "-"
                                       ) : (
                                          <div
                                             className={`place-order-status ${PlaceOrderStatus.getLabelClass(
                                                data?.status.value
                                             )}`}
                                          >
                                             {PlaceOrderStatus.getDisplayTextKey(
                                                data?.status.value
                                             )}
                                          </div>
                                       )}
                                    </div>
                                 )
                              },
                           },
                           {
                              title: "",
                              render: data => {
                                 return (
                                    <>
                                       {warehouse ? (
                                          "-"
                                       ) : (
                                          <>
                                             {data?.isAlreadySubstitute ? (
                                                <>
                                                   <div>
                                                      {data?.substitutename}
                                                   </div>
                                                </>
                                             ) : (
                                                <div className="d-flex">
                                                   <div className="place-order-table-search-con ">
                                                      <AutoComplete
                                                         isDisabled={warehouse}
                                                         value={data.status}
                                                         isSearchable={false}
                                                         placeholder="Select Status"
                                                         customStyles={
                                                            customStyles.WarehouseIM
                                                         }
                                                         classNamePrefix="place-order-table-search"
                                                         options={statusOptions.map(
                                                            el => ({
                                                               ...el,
                                                               ...(el.value ===
                                                                  PlaceOrderStatus.COMPLETED &&
                                                                  data.quantity -
                                                                     data.quantityreceived <=
                                                                     1 && {
                                                                     isDisabled: true,
                                                                  }),
                                                            })
                                                         )}
                                                         onChange={val =>
                                                            handleSelectStatusChange(
                                                               val,
                                                               data.id,
                                                               data.itemsId
                                                            )
                                                         }
                                                      />
                                                   </div>

                                                   {data?.isItemSelect && (
                                                      <div className="place-order-table-search-con ms-2">
                                                         <AutoComplete
                                                            // Commented this bcz of refetch issue on blur
                                                            // handleBlur={() =>
                                                            //     handleItemSelectBlur(
                                                            //         setModifiedData,
                                                            //         data.id
                                                            //     )
                                                            // }
                                                            onInputChange={val =>
                                                               handleSearch(
                                                                  val,
                                                                  data.id,
                                                                  data.itemsId
                                                               )
                                                            }
                                                            isClearable={true}
                                                            value={data?.itemData?.data.filter(
                                                               el =>
                                                                  data?.itemData
                                                                     .selectedOption ===
                                                                  el.label
                                                            )}
                                                            isLoading={
                                                               data?.itemData
                                                                  ?.fetching
                                                            }
                                                            placeholder="Select Item"
                                                            customStyles={
                                                               customStyles.WarehouseIM
                                                            }
                                                            classNamePrefix="place-order-table-search"
                                                            options={
                                                               data?.itemData
                                                                  ?.data
                                                            }
                                                            onChange={val =>
                                                               handleSelectItemChange(
                                                                  val,
                                                                  data.id
                                                               )
                                                            }
                                                         />
                                                      </div>
                                                   )}
                                                </div>
                                             )}
                                          </>
                                       )}
                                    </>
                                 )
                              },
                           },
                           {
                              title: COLUMNS.QUANTITY_REMANING,
                              render: data =>
                                 warehouse
                                    ? "-"
                                    : (
                                         data?.quantity - data?.quantityreceived
                                      )?.toFixed(2),
                           },
                           {
                              title: COLUMNS.ORDERED_QUANTITY,
                              render: data => {
                                 return (
                                    <div>
                                       <Input
                                          onKeyDown={e =>
                                             // e.key === "." ||
                                             e.key === "-" && e.preventDefault()
                                          }
                                          onChange={e =>
                                             handleInputChange(e, data.id)
                                          }
                                          className="view-detail-inv-req-p-ip-con create-order-modal-ip input-append-dollar-oq d-inline-block border-0 "
                                          placeholder="0"
                                          name="orderedQuantity"
                                          type="number"
                                          value={data.orderedQuantity}
                                          max={
                                             data?.quantity -
                                             data?.quantityreceived
                                          }
                                          min={0.1}
                                          step="0.000001"
                                          disabled={data?.isInputDisabled}
                                       />
                                       {
                                          <span className="text-danger d-block">
                                             {errorsData[data?.quantityError]}
                                          </span>
                                       }
                                    </div>
                                 )
                              },
                           },
                        ]}
                     />
                  </Col>
               </Row>
            </div>
            <div className="text-center my-4 d-flex justify-content-center align-items-center">
               <Button
                  isLoading={isAddingRequest}
                  size="lg"
                  className="gt-btn-grad-primary btn-height me-2 d-flex justify-content-center align-items-center place-order-btn"
                  title={<div>{footerBtnType}</div>}
                  onClick={handleConfirmRequest}
               />
            </div>
         </Form>
      </>
   )
}

export default PlaceOrderModal
